import Rest from '@/services/Rest';

/**
 * @typedef {ProductAllService}
 */
export default class ProductAllService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/all-product'
}
