import Rest from '@/services/Rest';

/**
 * @typedef {LinkService}
 */
export default class LinkService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/link'
}
